























































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { DeptModel } from '@/models/organization/DeptModel';
import { updateOrgGroup, createOrgGroup, getCompanyTreeData } from '@/api/system/OrganizationApi';

@Component({
  name: 'DeptForm'
})
export default class DeptForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前数据
  @Prop({ default: null }) currtent!: DeptModel;
  // 传过来的数据
  @Prop({ default: {}}) selectCompanyData;
  // 公司id
  @Prop({ default: ''}) groupId!: string;
  // 表单加载状态
  formLoading: boolean = false;
  // 上级部门下拉数据
  deptListData: any[] = [];
  parentDeptGroupSelectListLoading: boolean = false;
  // 表单数据
  deptData: any = {
    disabled: true
  };
  // 验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_please_enter_the_department_name'),
      trigger: 'blur'
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    },
    disabled: {
      required: true,
      message: this.$t('lang_please_select_a_status'),
      trigger: 'blur'
    },
    pid: {
      required: true,
      message: this.$t('lang_please_select_a_parent_dept'),
      trigger: 'change'
    },
  };

  menuTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  }

  created() {
    this.getParentDeptGroupSelectList(this.groupId);
    if (this.currtent) {
      this.deptData = { ...this.currtent };
    } else {
      this.deptData = {
        disabled: true,
        type: '1',
        pid: this.selectCompanyData.id,
        tenantId: this.selectCompanyData.tenantId
      };
    }
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList(groupId) {
    getCompanyTreeData({ parentId: groupId, types:['0','1'], cascadeType:"2", })
      .then((res: Record<any, any>) => {
        this.deptListData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let result;
      if (this.currtent) {
        result = updateOrgGroup(this.deptData);
      } else {
        result = createOrgGroup(this.deptData);
      }

      result
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
