




































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { deleteOrgGroup, getDeptCompanyPage, updateOrgGroup, getCompanyTreeData, getUserCompany } from '@/api/system/OrganizationApi';
import DeptForm from './dept-form.vue';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'DeptList',
  components: { DeptForm }
})
export default class DutyList extends Vue {
  // 表单搜索Model
  formSearchModel: any = { name: '' };
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  // 部门加载动画
  deptListLoading: boolean = false;
  //部门列表数据
  deptList: any[] = [];
  // 当前选择的数据
  currtent: any = null;
  // 是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
  // 公司树列表
  companyData: any[] = [];
  // 当前树选中的gruopId
  groupId: string = '';
  // 选中的公司数据
  selectCompanyData: any = {};
  // 是否显示新增按钮
  showAddFlag: boolean = false;
  // 当前选中的左侧树节点
  currentNodeKey: string = '';

  mounted() {
    // 获取公司列表
    this.getParentDeptGroupSelectList();
    this.getUserCompany();
  }

  @Watch('currentNodeKey', { deep: true })
  currentNodeKeyWatcher(id) {
    if (id.toString()) {
      (this.$refs['appTreeRef'] as any).setCurrentKey(id);
    } else {
      (this.$refs['appTreeRef'] as any).setCurrentKey(null);
    }
  }

  /**
   * 获取当前登录人的公司信息
   */
  async getUserCompany() {
    let params = {
      tenantId: UserUtil.getUser().tenantId,
      userId: UserUtil.getUser().id
    };
    let { data } = await getUserCompany(params);
    this.groupId = data[0].id;
    this.currentNodeKey = data[0].id;
    // 获取部门列表
    this.getDeptList();
  }

  /**
   * 启用或禁用
   */
  disabledChange(row) {
    let copyData = { ...row };
    copyData.disabled = !copyData.disabled;
    updateOrgGroup(copyData).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getDeptList();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList() {
    getCompanyTreeData({ type: '0' })
      .then((res: Record<any, any>) => {
        this.companyData = res.data;
      })
      .finally(() => {});
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    this.selectCompanyData = { ...data };
    if (data.id) {
      this.showAddFlag = true;
      this.groupId = data.id;
      this.pageIndex = 1;
      this.getDeptList();
    }
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deleteOrgGroup({ groupId: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getDeptList();
      }
    });
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getDeptList();
  }

  /**
   * 重置搜索按钮
   */
  handleSearchReset() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getDeptList();
  }

  /**
   * 添加新增编辑弹框
   */
  addForm(row?: any) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 获取部门数据
   */
  getDeptList() {
    let params = {
      name: this.formSearchModel.name,
      type: '1',
      parentId: this.groupId ? this.groupId : UserUtil.getUser().tenantId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.deptListLoading = true;
    getDeptCompanyPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.deptList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.deptListLoading = false;
      });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getDeptList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getDeptList();
  }
}
